import { get } from 'svelte/store';

import { CognitoUserAttribute } from 'amazon-cognito-identity-js/dist/amazon-cognito-identity.js';

import { cognitoUserPoolStore } from '../stores';

export const register = (username, password, attributes) => {
  return new Promise((resolve, reject) => {
    const { userPool } = get(cognitoUserPoolStore);

    const attributeList = Object.entries(attributes).map(([key, value]) => new CognitoUserAttribute({ Name: key, Value: value }));
    userPool.signUp(username, password, attributeList, null, (error, data) => {
      if (error) {
        return reject(error);
      }

      resolve(data);
    });
  });
};
