<script>
  import { Router, Route } from 'svelte-navigator';

  import Home from './pages/Home.svelte';
  import Login from './pages/Login.svelte';
  import Registration from './pages/Registration.svelte';
  import RegistrationVerify from './pages/RegistrationVerify.svelte';
  import ResetPassword from './pages/ResetPassword.svelte';
  import SetNewPassword from './pages/SetNewPassword.svelte';

  import routes from './routes';
</script>

<Router>
  <Route path={routes.login}>
    <Login />
  </Route>
  <Route path={routes.registration}>
    <Registration />
  </Route>
  <Route path={routes.registrationVerify}>
    <RegistrationVerify />
  </Route>
  <Route path={routes.resetPassword}>
    <ResetPassword />
  </Route>
  <Route path={routes.setNewPassword}>
    <SetNewPassword />
  </Route>
  <Route path="*">
    <Home />
  </Route>
</Router>
