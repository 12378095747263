<script>
  import { Button, FormGroup, InlineNotification, Link, PasswordInput, TextInput, Tile } from 'carbon-components-svelte';
  import { Form } from 'sveltejs-forms';
  import { useNavigate } from 'svelte-navigator';
  import * as yup from 'yup';

  import LoadingButton from '../components/LoadingButton.svelte';
  import { brand, login, redirectToDestination } from '../services';

  const schema = yup.object().shape({
    username: yup.string().required('Enter your email address!'),
    password: yup.string().required('Enter your password!'),
  });

  let errorMessage, showSuccessBanner, isLoading;

  const navigate = useNavigate();

  let showEmailVerifiedNotification = localStorage.getItem('verified_email');
  localStorage.removeItem('verified_email');

  let showChangedPasswordNotification = localStorage.getItem('password_changed');
  localStorage.removeItem('password_changed');

  async function onFormSubmit({ detail: { values, setSubmitting } }) {
    setSubmitting(true);
    errorMessage = null;
    showEmailVerifiedNotification = null;
    showChangedPasswordNotification = null;
    isLoading = true;

    try {
      const { resetRequired, tokens } = await login(values.username, values.password);

      if (resetRequired) {
        navigate('/set-new-password', { state: { fromLogin: true, username: values.username } });
      } else {
        showSuccessBanner = true;
        setTimeout(() => {
          redirectToDestination(tokens.idToken.jwtToken);
        }, 500);
      }
    } catch (error) {
      setSubmitting(false);
      if (error.code === 'InternalFailure' || error.code === 'ServiceUnavailable') {
        errorMessage = 'An error occurred. Please try again later.';
      } else if (error.code === 'UserNotConfirmedException') {
        navigate('/verify', { state: { fromLogin: true, username: values.username } });
      } else {
        errorMessage = 'The email address and password entered did not match our records. Please try again.';
      }
    } finally {
      isLoading = false;
    }
  }
</script>

<svelte:head>
  <title>{brand.title} Authentication Portal - Login</title>
</svelte:head>

<Tile light>
  <div class="mst-global-custom-css-form">
    <img alt="{brand.title} Logo" class="form-logo" src="./{brand.logo}" />
    <h1 class="bx--visually-hidden">Login</h1>

    {#if showEmailVerifiedNotification}
      <InlineNotification
        kind="success"
        title="Success"
        subtitle="Your email address has been verified. You can now log in."
        lowContrast={true}
      />
    {/if}
    {#if showChangedPasswordNotification}
      <InlineNotification
        kind="success"
        title="Success"
        subtitle="Your password has been changed successfully. You can now log in."
        lowContrast={true}
      />
    {/if}
    {#if showSuccessBanner}
      <InlineNotification kind="success" title="Success!" subtitle="Redirecting..." lowContrast={true} />
    {:else}
      {#if errorMessage}
        <InlineNotification kind="error" title="Error:" subtitle={errorMessage} lowContrast={true} />
      {/if}
      <Form
        {schema}
        validateOnBlur={true}
        validateOnChange={true}
        on:submit={onFormSubmit}
        let:isSubmitting
        let:setValue
        let:errors
        let:touched
      >
        <FormGroup legendText="Email Address">
          <TextInput
            autocomplete="email"
            invalid={touched['username'] && !!errors['username']}
            invalidText={errors['username']}
            name="username"
            placeholder="Email address"
            type="email"
            on:change={({ target }) => setValue('username', target.value)}
          />
        </FormGroup>
        <FormGroup class="form-group-password" legendText="Password">
          <PasswordInput
            autocomplete="current-password"
            hideLabel
            invalid={touched['password'] && !!errors['password']}
            invalidText={errors['password']}
            labelText="Password"
            name="password"
            placeholder="Password"
            on:change={({ target }) => setValue('password', target.value)}
          />
          <Link on:click={() => navigate('/reset-password')}>Forgot Password?</Link>
        </FormGroup>
        <div class="form-controls">
          <Button kind="ghost" type="button" on:click={() => navigate('/registration')}>Register</Button>
          <LoadingButton disabled={isSubmitting} kind="primary" type="submit" {isLoading}>Login</LoadingButton>
        </div>
      </Form>
    {/if}
  </div>
</Tile>
