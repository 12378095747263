<script>
  import { onMount } from 'svelte';

  import { FormGroup, InlineNotification, TextInput, Tile } from 'carbon-components-svelte';
  import { Form } from 'sveltejs-forms';
  import { useLocation, useNavigate } from 'svelte-navigator';
  import * as yup from 'yup';

  import LoadingButton from '../components/LoadingButton.svelte';
  import { brand, registrationVerify, resendVerificationCode } from '../services';

  const schema = yup.object().shape({
    verificationCode: yup.string().required('Enter your email verification code!'),
  });

  const location = useLocation();
  const navigate = useNavigate();

  let errorMessage, username, showResendSuccessNotification, isLoading;

  onMount(async () => {
    username = $location.state?.username;
    if (!username) {
      navigate('/login');
      return;
    }

    if ($location.state?.fromLogin) {
      await resendCode({ notification: false });
    }
  });

  async function onFormSubmit({ detail: { values, setSubmitting } }) {
    setSubmitting(true);
    errorMessage = null;
    showResendSuccessNotification = null;
    isLoading = 'submit';

    try {
      await registrationVerify(username, values.verificationCode);
      localStorage.setItem('verified_email', true);
      navigate('/login');
    } catch (error) {
      setSubmitting(false);
      errorMessage =
        error.code === 'InternalFailure' || error.code === 'ServiceUnavailable'
          ? 'An error occurred. Please try again later.'
          : 'Failed to verify email address. Please verify your submission and try again.';
    } finally {
      isLoading = false;
    }
  }

  const resendCode = async ({ notification = true }) => {
    isLoading = 'resend';
    try {
      await resendVerificationCode(username);
      if (notification) {
        showResendSuccessNotification = true;
      }
    } catch (error) {
      if (notification) {
        errorMessage = 'Failed to resend verification code. Please try again later.';
      }
      console.error('[RegistrationVerify] Failed to resend verification code!', error);
    } finally {
      isLoading = false;
    }
  };
</script>

<svelte:head>
  <title>{brand.title} Authentication Portal - Verify Email Address</title>
</svelte:head>

<Tile light>
  <div class="mst-global-custom-css-form">
    <img alt="{brand.title} Logo" class="form-logo" src="./{brand.logo}" />
    <h1 class="bx--visually-hidden">Verify Email Address</h1>
    <p class="form-coach-text">We sent a verification code to your email address. Please enter the code into the form below.</p>
    {#if errorMessage}
      <InlineNotification kind="error" title="Error:" subtitle={errorMessage} lowContrast={true} />
    {/if}
    {#if showResendSuccessNotification}
      <InlineNotification
        kind="success"
        title="Success"
        subtitle="Verification code successfully sent. Please check your email."
        lowContrast={true}
      />
    {/if}
    <Form
      {schema}
      validateOnBlur={true}
      validateOnChange={true}
      on:submit={onFormSubmit}
      let:isSubmitting
      let:setValue
      let:errors
      let:touched
    >
      <FormGroup legendText="Verification code">
        <TextInput
          autocomplete="one-time-code"
          invalid={touched['verificationCode'] && !!errors['verificationCode']}
          invalidText={errors['verificationCode']}
          name="verificationCode"
          placeholder="Verification code"
          type="text"
          on:change={({ target }) => setValue('verificationCode', target.value)}
        />
      </FormGroup>

      <div class="form-controls">
        <LoadingButton kind="ghost" type="button" on:click={resendCode} isLoading={isLoading === 'resend'}>Resend Code</LoadingButton>
        <LoadingButton kind="primary" type="submit" disabled={isSubmitting} isLoading={isLoading === 'submit'}>Verify</LoadingButton>
      </div>
    </Form>
  </div>
</Tile>
