import { get } from 'svelte/store';

import { CognitoUser } from 'amazon-cognito-identity-js/dist/amazon-cognito-identity.js';

import { cognitoUserPoolStore } from '../stores';

export const registrationVerify = (username, verificationCode) => {
  return new Promise((resolve, reject) => {
    const { userPool, storage } = get(cognitoUserPoolStore);

    const user = new CognitoUser({
      Pool: userPool,
      Storage: storage,
      Username: username,
    });
    user.confirmRegistration(verificationCode, true, (error, result) => {
      if (error) {
        return reject(error);
      }

      return resolve();
    });
  });
};

export const resendVerificationCode = (username) => {
  return new Promise((resolve, reject) => {
    const { userPool, storage } = get(cognitoUserPoolStore);

    const user = new CognitoUser({
      Pool: userPool,
      Storage: storage,
      Username: username,
    });

    user.resendConfirmationCode((error, result) => {
      if (error) {
        return reject(error);
      }

      return resolve();
    });
  });
};
