<script>
  import { Button, FormGroup, InlineNotification, Link, PasswordInput, TextInput, Tile } from 'carbon-components-svelte';
  import { Form } from 'sveltejs-forms';
  import { useNavigate } from 'svelte-navigator';
  import * as yup from 'yup';

  import LoadingButton from '../components/LoadingButton.svelte';
  import { brand, register } from '../services';

  const schema = yup.object().shape({
    username: yup.string().email('Enter a valid email!').required('Enter your email address!'),
    name: yup.string().required('Enter your full name!'),
    'custom:organization_name': yup.string().required('Enter your organization name!'),
    password: yup
      .string()
      .required('Enter your password!')
      .min(10)
      .max(255)
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[-+_!@#$%^&*., ?])(^\S*$)/,
        'Password must contain at least one uppercase letter, one lowercase letter, one digit, and one symbol. Passwords cannot contain spaces.'
      ),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], "Passwords don't match!")
      .required('Enter your password again!'),
  });

  let errorMessage, isLoading, showForgotPasswordBanner;

  const navigate = useNavigate();

  async function onFormSubmit({ detail: { values, setSubmitting } }) {
    setSubmitting(true);
    errorMessage = null;
    showForgotPasswordBanner = false;
    isLoading = true;

    try {
      const { username, password, confirmPassword, ...attributes } = values;
      await register(username, password, attributes);
      navigate('/verify', { state: { fromLogin: false, username } });
    } catch (error) {
      setSubmitting(false);
      if (error.code === 'InternalFailure' || error.code === 'ServiceUnavailable') {
        errorMessage = 'An error occurred. Please try again later.';
      } else if (error.code === 'UsernameExistsException') {
        showForgotPasswordBanner = true;
      } else {
        errorMessage = 'Failed to complete registration. Please try again shortly.';
      }
    } finally {
      isLoading = false;
    }
  }
</script>

<svelte:head>
  <title>{brand.title} Authentication Portal - Registration</title>
</svelte:head>

<Tile light>
  <div class="mst-global-custom-css-form">
    <img alt="{brand.title} Logo" class="form-logo" src="./{brand.logo}" />
    <h1 class="bx--visually-hidden">Registration</h1>
    {#if errorMessage}
      <InlineNotification kind="error" title="Error:" subtitle={errorMessage} lowContrast={true} />
    {/if}
    {#if showForgotPasswordBanner}
      <InlineNotification kind="warning" title="Warning:" subtitle="" lowContrast={true}>
        <p class="bx--inline-notification__subtitle forgot-password-subtitle">
          That email address is already associated with an account.
          <Link on:click={() => navigate('/reset-password')}>Forgot Password?</Link>
        </p>
      </InlineNotification>
    {/if}
    <Form
      {schema}
      validateOnBlur={true}
      validateOnChange={true}
      on:submit={onFormSubmit}
      let:isSubmitting
      let:setValue
      let:errors
      let:touched
    >
      <FormGroup legendText="Email Address">
        <TextInput
          autocomplete="email"
          invalid={touched['username'] && !!errors['username']}
          invalidText={errors['username']}
          name="username"
          placeholder="Email address"
          type="email"
          on:change={({ target }) => setValue('username', target.value)}
        />
      </FormGroup>
      <FormGroup legendText="Full Name">
        <TextInput
          autocomplete="name"
          invalid={touched['name'] && !!errors['name']}
          invalidText={errors['name']}
          name="name"
          placeholder="Full name"
          type="text"
          on:change={({ target }) => setValue('name', target.value)}
        />
      </FormGroup>
      <FormGroup legendText="Organization Name">
        <TextInput
          autocomplete="organization"
          invalid={touched['custom:organization_name'] && !!errors['custom:organization_name']}
          invalidText={errors['custom:organization_name']}
          name="custom:organization_name"
          placeholder="Organization Name"
          type="text"
          on:change={({ target }) => setValue('custom:organization_name', target.value)}
        />
      </FormGroup>
      <FormGroup class="form-group-password" legendText="Password">
        <PasswordInput
          autocomplete="new-password"
          hideLabel
          invalid={touched['password'] && !!errors['password']}
          invalidText={errors['password']}
          labelText="Password"
          maxlength="255"
          minlength="10"
          name="password"
          placeholder="Password"
          on:change={({ target }) => setValue('password', target.value)}
        />
      </FormGroup>
      <FormGroup class="form-group-password" legendText="Confirm Password">
        <PasswordInput
          autocomplete="new-password"
          hideLabel
          invalid={touched['confirmPassword'] && !!errors['confirmPassword']}
          invalidText={errors['confirmPassword']}
          labelText="Confirm Password"
          name="confirmPassword"
          placeholder="Confirm Password"
          on:change={({ target }) => setValue('confirmPassword', target.value)}
        />
      </FormGroup>
      <div class="form-controls">
        <Button kind="ghost" type="button" on:click={() => navigate('/login')}>Login</Button>
        <LoadingButton kind="primary" type="submit" disabled={isSubmitting} {isLoading}>Register</LoadingButton>
      </div>
    </Form>
  </div>
</Tile>

<style>
  .forgot-password-subtitle :global(.bx--link) {
    cursor: pointer;
  }
</style>
