<script>
  import { onMount } from 'svelte';

  import { FormGroup, InlineNotification, PasswordInput, TextInput, Tile } from 'carbon-components-svelte';
  import { Form } from 'sveltejs-forms';
  import { useNavigate, useLocation } from 'svelte-navigator';
  import * as yup from 'yup';

  import LoadingButton from '../components/LoadingButton.svelte';
  import { brand, completeNewPasswordChallenge, resetPassword, setNewPassword } from '../services';
  import { cognitoUserStore } from '../stores';

  const navigate = useNavigate();
  const location = useLocation();

  let currentLoader, errorMessage, showResendSuccessNotification, username, verificationCodeRequired;

  onMount(async () => {
    username = $location.state?.username;
    verificationCodeRequired = !$location.state?.fromLogin;

    if (!username) {
      navigate('/reset-password');
    }
    if (!verificationCodeRequired && !$cognitoUserStore) {
      navigate('/login');
    }
  });

  async function onFormSubmit({ detail: { values, setSubmitting } }) {
    setSubmitting(true);
    errorMessage = null;
    showResendSuccessNotification = false;
    currentLoader = 'submit';

    try {
      const saveOperation = verificationCodeRequired
        ? () => setNewPassword(username, values.verificationCode, values.password)
        : () => completeNewPasswordChallenge(values.password);
      await saveOperation();

      localStorage.setItem('password_changed', true);
      navigate('/login');
    } catch (error) {
      setSubmitting(false);
      if (error.code === 'InternalFailure' || error.code === 'ServiceUnavailable') {
        errorMessage = 'An error occurred. Please try again later.';
      } else {
        errorMessage = 'Failed to set password. Please verify your submission and try again.';
      }
    } finally {
      currentLoader = false;
    }
  }

  async function resendCode() {
    currentLoader = 'resend';
    try {
      await resetPassword(username);
      showResendSuccessNotification = true;
    } catch (error) {
      errorMessage = 'Failed to resend verification code. Please try again later.';
      console.error('[RegistrationVerify] Failed to resend verification code!', error);
    } finally {
      currentLoader = false;
    }
  }

  $: schema = yup.object().shape({
    verificationCode: verificationCodeRequired ? yup.string().required('Enter your email verification code!') : undefined,
    password: yup
      .string()
      .required('Enter your password!')
      .min(10)
      .max(255)
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[-+_!@#$%^&*., ?])(^\S*$)/,
        'Password must contain at least one uppercase letter, one lowercase letter, one digit, and one symbol. Passwords cannot contain spaces.'
      ),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], "Passwords don't match!")
      .required('Enter your password again!'),
  });
</script>

<svelte:head>
  <title>{brand.title} Authentication Portal - Set Password</title>
</svelte:head>

<Tile light>
  <div class="mst-global-custom-css-form">
    <h1>Set Password</h1>
    <p class="form-coach-text">
      {#if verificationCodeRequired}
        We sent a verification code to your email address. Please enter the code into the form below.
      {:else}
        You must set a new password below to continue.
      {/if}
    </p>
    {#if errorMessage}
      <InlineNotification kind="error" title="Error:" subtitle={errorMessage} lowContrast={true} />
    {/if}
    {#if showResendSuccessNotification}
      <InlineNotification
        kind="success"
        title="Success"
        subtitle="Verification code successfully sent. Please check your email."
        lowContrast={true}
      />
    {/if}
    <Form
      {schema}
      validateOnBlur={true}
      validateOnChange={true}
      on:submit={onFormSubmit}
      let:isSubmitting
      let:setValue
      let:errors
      let:touched
    >
      {#if verificationCodeRequired}
        <FormGroup legendText="Verification code">
          <TextInput
            autocomplete="one-time-code"
            invalid={touched['verificationCode'] && !!errors['verificationCode']}
            invalidText={errors['verificationCode']}
            name="verificationCode"
            placeholder="Verification code"
            type="text"
            on:change={({ target }) => setValue('verificationCode', target.value)}
          />
        </FormGroup>
      {/if}
      <FormGroup class="form-group-password" legendText="Password">
        <PasswordInput
          autocomplete="new-password"
          hideLabel
          invalid={touched['password'] && !!errors['password']}
          invalidText={errors['password']}
          labelText="Password"
          name="password"
          placeholder="Password"
          on:change={({ target }) => setValue('password', target.value)}
        />
      </FormGroup>
      <FormGroup class="form-group-password" legendText="Confirm Password">
        <PasswordInput
          autocomplete="new-password"
          hideLabel
          invalid={touched['confirmPassword'] && !!errors['confirmPassword']}
          invalidText={errors['confirmPassword']}
          labelText="Confirm Password"
          name="confirmPassword"
          placeholder="Confirm Password"
          on:change={({ target }) => setValue('confirmPassword', target.value)}
        />
      </FormGroup>
      <div class="form-controls">
        {#if verificationCodeRequired}
          <LoadingButton kind="ghost" type="button" on:click={resendCode} isLoading={currentLoader === 'resend'}>Resend Code</LoadingButton>
        {/if}
        <LoadingButton kind="primary" type="submit" disabled={isSubmitting} isLoading={currentLoader === 'submit'}>Submit</LoadingButton>
      </div>
    </Form>
  </div>
</Tile>
