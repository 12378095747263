import 'carbon-components-svelte/css/all.css';
import './mst-global-custom-css-form.css';

import App from './App.svelte';

const app = new App({
  target: document.body,
});

export default app;
