<script>
  import { Column, Row } from 'carbon-components-svelte';

  const BREAKPOINTS = {
    sm: {
      span: 4,
      offset: 0,
    },
    md: {
      span: 6,
      offset: 1,
    },
    lg: {
      span: 8,
      offset: 4,
    },
    xlg: {
      span: 6,
      offset: 5,
    },
    max: {
      span: 4,
      offset: 6,
    },
  };
</script>

<main>
  <Row>
    <Column {...BREAKPOINTS}>
      <slot />
    </Column>
  </Row>
</main>
