import { get } from 'svelte/store';

import { appConfigStore, redirect } from '../stores';
import { storeAccessToken } from './session';

function isValidRedirectUrl(appConfig, redirectUrl) {
  try {
    const url = new URL(redirectUrl);
    return url.hostname.endsWith(appConfig.cognitoCookieStorageDomain);
  } catch (error) {
    console.error('[isValidRedirectUrl] Invalid or malformed URL!', error);
    return false;
  }
}

export function redirectToDestination(accessToken) {
  const appConfig = get(appConfigStore);

  localStorage.removeItem('auth_redirect');

  storeAccessToken(appConfig, accessToken);

  const redirectUrl = get(redirect) ?? appConfig.fallbackRedirect ?? 'https://my.maystreet.com';
  window.location.href = redirectUrl;
}

export function stashDestinationUrl(appConfig) {
  const { search } = window.location;
  const redirectUrl = new URLSearchParams(search).get('redirect');

  if (!redirectUrl || !isValidRedirectUrl(appConfig, redirectUrl)) {
    return;
  }

  console.debug('[redirect] Redirect URL found! Stashing for later...');
  redirect.update(() => redirectUrl);
}
