<script>
  import { onMount } from 'svelte';

  import { useNavigate } from 'svelte-navigator';

  const navigate = useNavigate();

  onMount(async () => {
    navigate('/login');
  });
</script>
