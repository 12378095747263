<script>
  import { onMount, tick } from 'svelte';

  import { CookieStorage, CognitoUserPool } from 'amazon-cognito-identity-js/dist/amazon-cognito-identity.js';
  import { InlineNotification, Tile } from 'carbon-components-svelte';

  import AppPages from './AppPages.svelte';
  import Shell from './Shell.svelte';
  import { brand, getConfig, getSessionAccessToken, redirectToDestination, stashDestinationUrl } from './services';
  import { appConfigStore, cognitoUserPoolStore } from './stores';

  $: document.documentElement.setAttribute('theme', brand.theme);

  let metadata = { initialized: false, error: undefined };

  onMount(async () => {
    try {
      const appConfig = await getConfig();

      stashDestinationUrl(appConfig);

      const storage = new CookieStorage({
        domain: appConfig.cognitoCookieStorageDomain,
        secure: appConfig.useSecureCookieStorage,
      });
      const userPool = new CognitoUserPool({
        ClientId: appConfig.cognitoClientId,
        Storage: storage,
        UserPoolId: appConfig.cognitoUserPoolId,
      });
      appConfigStore.update(() => appConfig);
      cognitoUserPoolStore.update(() => ({ userPool, storage }));
      await tick();

      const accessToken = await getSessionAccessToken();
      if (accessToken) {
        redirectToDestination(accessToken);
      } else {
        metadata.initialized = true;
      }
    } catch (error) {
      metadata.error = error;
      console.error('[App] An error occurred while retrieving base configuration!', error);
    } finally {
      window.mst.closeLoader();
    }
  });
</script>

<Shell>
  {#if metadata.error}
    <Tile>
      <InlineNotification kind="error" title="Error:" subtitle="The service is unavailable." lowContrast={true} hideCloseButton={true} />
    </Tile>
  {:else if metadata.initialized}
    <AppPages />
  {/if}
</Shell>
