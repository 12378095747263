import { get } from 'svelte/store';
import Cookies from 'js-cookie';

import { cognitoUserPoolStore } from '../stores';

// NOTE: don't change, this is part of the auth portal public interface,
// other services rely on this cookie to exist after a redirect
const ACCESS_TOKEN_COOKIE_NAME = 'mst.auth-portal.access-token';

export async function getSessionAccessToken() {
  const { userPool } = get(cognitoUserPoolStore);

  try {
    const cognitoUser = userPool?.getCurrentUser();
    if (!cognitoUser) {
      return undefined;
    }

    return await new Promise((resolve, reject) => {
      cognitoUser.getSession((error, cognitoSession) => {
        if (error) {
          return reject(error);
        }

        resolve(cognitoSession.isValid() ? cognitoSession.accessToken.jwtToken : undefined);
      });
    });
  } catch (error) {
    console.error('[App] An error occurred while retrieving the current user session!', error);
    return undefined;
  }
}

export function storeAccessToken(appConfig, accessToken) {
  Cookies.set(ACCESS_TOKEN_COOKIE_NAME, accessToken, {
    domain: appConfig.cognitoCookieStorageDomain,
    secure: appConfig.useSecureCookieStorage,
    sameSite: 'Strict',
    // expire after 5 minutes, it's only really required by the next redirect
    expires: new Date(new Date().getTime() + 5 * 60 * 1000),
    path: '/',
  });
}
